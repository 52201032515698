import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import { TypeA, TypeC } from "common/callouts";
import heroImageRef from "images/face/immersivehydrationtechnology/Face-Web-Page-Banner-Molecules.webp";
import img1 from "images/face/immersivehydrationtechnology/immersive-hydration-technology-front.webp";
import img2 from "images/face/immersivehydrationtechnology/Immersive-Hydration-Product-Lineup.webp";

import img6 from "images/callouts/eucerin-face-product-family.webp";
import img7 from "images/callouts/cout-footer-daily-skin.webp";
//import img8 from "images/callouts/cout-footer-facecare.webp";
import img8 from "images/callouts/sunproducts2x.webp";
import "./immersivehydrationtechnology.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function ImmersiveHydrationTechnology({ pageContext }) {
  return (
    <Layout
      pageClass="page-immersive-hydration-technology page-level-3"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo pageTitle="Immersive Hydration Technology" pageDescription="" />

      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <CategoryDetails
              headBgColor="#67C1ED"
              headText="Immersive Hydration Technology"
              infoText="Purpose-built formulas that feature <nobr>multi-weight</nobr> hyaluronic acid (HA) and antioxidant complexes"
              bodyText="Help your patients improve the appearance of aging skin."
              productInfoImg={img1}
              productInfoImgAlt="Two large and one small molecules"
            >
              <h5 className="picton-blue">
                Hydrating <nobr>multi-weight</nobr> <nobr>HA-enriched</nobr>{" "}
                formula
              </h5>
              <p>
                Provides hydration throughout the surface layers of the skin
              </p>
              <ul className="picton-bullets">
                <li>
                  <span>
                    <span className="font-bold">HA 2000 kDa: </span>Hydrates
                    skin surface
                  </span>
                </li>
                <li>
                  <span>
                    <span className="font-bold">HA 52 kDa: </span>Hydrates deep
                    within the surface layers of the skin
                  </span>
                </li>
              </ul>
              <h5 className="picton-blue">
                Powerful antioxidant-enriched formulas
              </h5>
              <p>Defend against damaging free radicals</p>
              <ul className="picton-bullets">
                <li>
                  <span>
                    <span className="font-bold">
                      Soy-Derived <nobr>Glycine-Saponin</nobr>:{" "}
                    </span>{" "}
                    Effective, natural substance helps scavenge free radicals
                  </span>
                </li>
                <li>
                  <span>
                    <span className="font-bold">Glycyrrhetinic Acid:</span>{" "}
                    Helps defend the skin from free radicals
                  </span>
                </li>
                <li>
                  <span>
                    <span className="font-bold">Vitamin E (Tocopherol):</span>{" "}
                    Helps guard against free radicals
                  </span>
                </li>
                <li>
                  <span>
                    <span className="font-bold">
                      Vitamin C (Sodium Ascorbyl Phosphate):
                    </span>{" "}
                    Helps guard against free radicals
                  </span>
                </li>
              </ul>
            </CategoryDetails>
          </section>
        </div>

        <section className="callouts-container gradient-bg section-support-resource">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                <TypeC
                  buttonUrl="/face/eucerinfaceproducts"
                  buttonText="EXPLORE FACE PRODUCTS"
                  className="center-v"
                >
                  <p className="reg-text-big">
                    Introducing Immersive Hydration technology
                  </p>
                  <p>
                    See how Eucerin<sup>®</sup> Face utilizes Immersive
                    Hydration technology— <br className="desktop-only" />
                    multi-weight hyaluronic acid and antioxidant complexes in
                    lightweight, elegant formulations.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                <img
                  src={img2}
                  alt="Immersive hydration product line"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Resources for Face Care"
                  caption="Find resources to support your face care recommendations"
                  buttonText="BROWSE RESOURCES"
                  buttonUrl="/resources"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img7}
                  imgAlt="EXPLORE BODY CARE"
                  caption="Clinically proven therapeutic solutions for daily skin care"
                  buttonText="EXPLORE BODY CARE"
                  buttonUrl="/body"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img8}
                  imgAlt="EXPLORE SUN CARE"
                  caption=" Sun protection for everyone under the sun"
                  buttonText="EXPLORE SUN CARE"
                  buttonUrl="/sun"
                  imgStyles={{ width: 236 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ImmersiveHydrationTechnology;
